<template lang="">
    <div class="application-confirmation">
        <div>
            <img :src="applicationImage" alt="applicationImage"/>
        </div>
        <div class="application-confirmation-title">{{ $t('APPLICATION.SEND_SUCCESS_TITLE') }}</div>
        <span class="my-4">
            <i class="icon-5x far fa-check-circle text-success"></i>
        </span>
        <p class="application-confirmation-text" v-html="text"></p>
        <div>
            <st-button
            variant="primary"
            :callback="goToList"
            >{{ $t('GENERAL.BUTTON.OK') }}</st-button>
        </div>
    </div>
</template>
<script>
export default {
    name: 'ApplicationConfirmation',
    props: {
        id: {
            type: String,
        },
    },
    data() {
        return {
            applicationImage: `${process.env.BASE_URL}media/st-application-registred.png`
        }
    },
    computed: {
        text() {
            return `${this.$t(
                        "APPLICATION.SEND_SUCCESS_TEXT",
                        { number: this.id }
                    )}`;
        }
    },
    methods: {
        goToList() {
            this.$router.push({
                    name: 'applications'
                });
        },
    },
}
</script>
<style lang="">

</style>
